<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" :tabId="true"></go-back-btn>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headersResultsLoteDeuda"
          :items="comprobantesLoteDeuda"
          class="elevation-1"
          :hide-default-footer="true"
          dense
        >
          <template v-slot:[`item.importe`]="{ item }">
            <span>{{ addDecimals(item.importe) }}</span>
          </template>
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title class="primary--text"
                >Lote de deuda N°
                {{ loteDeudaId + " - " + nombre }}</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`body.append`]="{ headers }">
            <tr class="totales text-end">
              <td v-for="(header, i) in headers" :key="i">
                <div v-if="header.value == 'entidadFinancieraNombre'">
                  Total
                </div>
                <div v-if="header.value == 'importe'">
                  <strong> {{ addDecimals(totalImporte) }}</strong>
                </div>
                <div v-if="header.value == 'cantidad'">
                  <strong> {{ totalCantidad }}</strong>
                </div>
                <div v-else></div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headersEnviosRealizados"
          :items="enviosRealizados"
          class="elevation-1"
          :hide-default-footer="true"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title class="primary--text"
                >Envíos realizados</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="item.procesoEnvioId === 1"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEnvioSiro(item)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver envio</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headersDestinos"
          :items="envioConfig"
          class="elevation-1"
          :hide-default-footer="true"
          dense
        >
          <template v-slot:[`item.importe`]="{ item }">
            <span>{{ addDecimals(item.importe) }}</span>
          </template>
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title class="primary--text"
                >Posibles destinos</v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="sendLoteDeuda(item)"
                >
                  {{ sendFileIcon }}
                </v-icon>
              </template>
              <span>Enviar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalVariableXPantalla"
      v-model="modalVariableXPantalla"
      max-width="1000px"
      @keydown.esc="closeModal()"
      persistent
    >
      <div>
        <v-card>
          <v-card-title>
            <span class="primary--text"
              >Completar datos pedidos por pantalla</span
            >
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headersPantalla"
              item-key="id"
              dense
              hide-default-footer
              :items="datosPantalla"
              class="elevation-1"
            >
              <template v-slot:[`item.campoValor`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-edit-dialog
                        v-model="item.campoValor"
                        large
                        @open="openEditDialog(item.campoValor)"
                        cancel-text="Cancelar"
                        save-text="Guardar"
                        persistent
                        @save="saveEditValor(item)"
                        @cancel="cancelEditValor(item)"
                      >
                        {{ item.campoValor }}
                        <v-icon
                          :color="item.campoValor != null ? 'primary' : 'error'"
                          size="16"
                          >{{ editIcon }}</v-icon
                        >
                        <template v-slot:input>
                          <v-text-field
                            ref="edit-valor"
                            :rules="
                              rules.required.concat([
                                rules.requiredTrim(item.campoValor),
                              ])
                            "
                            v-model.trim="item.campoValor"
                            single-line
                            label="Editar valor"
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </div>
                  </template>
                  <span>Editar valor</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal" tabindex="-1"> Cerrar </v-btn>
            <v-btn
              type="submit"
              form="crud-form"
              color="primary"
              tabindex="-1"
              :loading="loadingBtn"
              @click="enviarLoteWithVariables()"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog
      v-if="modalExportCsv"
      v-model="modalExportCsv"
      max-width="570px"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title class="headline primary--text"
            >Se generará un archivo con los datos del envío.</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- botón para desactivar el recorrido del tab en el componente(ver de mejorar el funcionamiento sin necesitar el botón) -->
            <v-btn v-show="showBtnFalse" tabindex="-1"></v-btn>
            <v-btn
              color="primary"
              @click="closeModal()"
              class="py-0"
              tabindex="-1"
            >
              <vue-excel-xlsx
                v-if="macroLink"
                :data="resultsLoteDeuda"
                :columns="columns"
                :filename="resultsLoteDeuda[0].titulo"
                class="to-right"
              >
                Continuar
              </vue-excel-xlsx>
              <download-csv
                v-if="credicoop"
                :data="csvJsonToExport"
                :name="nombreArchivo"
              >
                Continuar
              </download-csv>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="modalSeeEnvioSiro"
      v-model="modalSeeEnvioSiro"
      max-width="85%"
      @keydown.esc="modalSeeEnvioSiro = false"
      persistent
    >
      <VerEnvioLoteSiro
        :itemEnvioSiro="itemEnvioSiro"
        @closeModal="closeModalVerEnvio"
      >
      </VerEnvioLoteSiro>
    </v-dialog>
  </v-container>
</template>

<script>
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import rules from "@/utils/helpers/rules";
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helper from "../../../../utils/helpers";
import JsonCSV from "vue-json-csv";
import helpersExcel from "@/utils/helpers/importExportExcel";
import VerEnvioLoteSiro from "@/components/modules/cuotas/devengamientos/VerEnvioLoteSiro.vue";

export default {
  components: {
    "go-back-btn": GoBackBtn,
    "download-csv": JsonCSV,
    VerEnvioLoteSiro,
  },
  created() {
    if (this.$route.params.loteDeudaId) {
      this.loteDeudaId = this.$route.params.loteDeudaId;
      this.entFacId = this.$route.params.entFacId;
      this.nombre = this.$route.params.nombre;
      this.fromLotesDeuda = true;
      this.getView();
    }
  },
  watch: {
    comprobantesLoteDeuda(val) {
      if (val.length > 0) {
        this.totalImporte = this.comprobantesLoteDeuda
          .map((x) => x.importe)
          .reduce((a, b) => a + b, 0);
        this.totalCantidad = this.comprobantesLoteDeuda
          .map((x) => x.cantidad)
          .reduce((a, b) => a + b, 0);
      } else {
        this.totalImporte = 0;
        this.totalCantidad = 0;
      }
    },
  },
  data() {
    return {
      sendFileIcon: enums.icons.FILE_SEND,
      editIcon: enums.icons.EDIT,
      modalVariableXPantalla: false,
      nombreArchivo: "",
      enviosRealizados: [],
      datosPantalla: [],
      rules: rules,
      modalExportCsv: false,
      seeIcon: enums.icons.SEE,
      modalSeeEnvioSiro: false,
      itemEnvioSiro: null,
      headersPantalla: [
        { text: "Campo", value: "value", sortable: false },
        { text: "Valor", value: "campoValor", sortable: false },
        {
          text: "Descripción",
          value: "description",
          align: "end",
          sortable: false,
        },
      ],
      routeToGo: "LotesDeDeuda",
      fromLotesDeuda: false,
      totalImporte: 0,
      totalCantidad: 0,
      entFinId: [],
      showBtnFalse: false,
      fPagoId: [],
      comprobantesLoteDeuda: [],
      envioConfig: [],
      loteDeudaId: null,
      mostrarBtnCsv: false,
      nombre: "",
      headersResultsLoteDeuda: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: false,
          value: "formaDePagoNombre",
        },
        {
          text: "Entidad",
          align: "start",
          sortable: false,
          value: "entidadFinancieraNombre",
        },
        {
          text: "Importe",
          align: "end",
          sortable: false,
          value: "importe",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: false,
          value: "cantidad",
        },
      ],
      headersEnviosRealizados: [
        {
          text: "Fecha de envío",
          align: "start",
          sortable: false,
          value: "fechaEnvio",
        },
        {
          text: "N° transacción",
          align: "end",
          sortable: false,
          value: "nroTransaccion",
        },
        {
          text: "Resultado",
          align: "end",
          sortable: false,
          value: "resultado",
        },
        {
          text: "Descripción del resultado",
          align: "end",
          sortable: false,
          value: "resultadoDescripcion",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end",
        },
      ],
      headersDestinos: [
        {
          text: "Destinos",
          align: "start",
          sortable: false,
          value: "procesoEnvioNombre",
        },
        {
          text: "Importe",
          align: "end",
          sortable: false,
          value: "importe",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: false,
          value: "cantidad",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      entFacId: null,
      previousValue: null,
      variablesEnvios: [{ id: 9999, value: null }],
      envioConfigToSend: {},
      envioToSiro: false,
      envioToCredicoop: false,
      csvJsonCompleto: [],
      csvJsonToExport: [],
      validateVariablesOk: null,
      activateDownloadCSV: false,
      comprobantesIdsToSend: [],
      credicoop: false,
      macroLink: false,
      resultsLoteDeuda: [],
      ultimoEnvio: null,
      columns: [
        {
          label: "Titulo",
          field: "titulo",
        },
        {
          label: "Duracion",
          field: "duracion",
        },
        {
          label: "Monto",
          field: "monto",
        },
        {
          label: "UrlExito",
          field: "urlExito",
        },
        {
          label: "UrlFallo",
          field: "urlFallo",
        },
        {
          label: "IdentificadorInterno",
          field: "identificadorInterno",
        },
        {
          label: "Celular",
          field: "celular",
        },
        {
          label: "Mail",
          field: "mail",
        },
        {
          label: "NumeroSucursal",
          field: "numeroSucursal",
        },
      ],
      loadingBtn: false,
    };
  },
  methods: {
    ...mapActions({
      getComprobantesEnvio: "devengamientos/getComprobantesEnvio",
      getEnvioConfiguracion: "devengamientos/getEnvioConfiguracion",
      sendSiroApi: "devengamientos/sendSiroApi",
      generarArchivoCredicoopCSV: "devengamientos/generarArchivoCredicoopCSV",
      generarVISAMOV: "devengamientos/generarVISAMOV",
      generarNaranjaCredito: "devengamientos/generarNaranjaCredito",
      generarBancoProviniciaPagos: "devengamientos/generarBancoProviniciaPagos",
      generarMastercardDebito: "devengamientos/generarMastercardDebito",
      generarVISADebito: "devengamientos/generarVISADebito",
      generarVISACredito: "devengamientos/generarVISACredito",
      generarPagosLink: "devengamientos/generarPagosLink",
      getNombreArchivo: "devengamientos/getNombreArchivo",
      generarPagoMisCuentasTxt: "devengamientos/generarPagoMisCuentasTxt",
      generarGalicia: "devengamientos/generarGalicia",
      generarMacroLink: "devengamientos/generarMacroLink",
      generarDebitoSupervielle: "devengamientos/generarDebitoSupervielle",
      generarPagosDebitoCredicoop: "devengamientos/generarPagosDebitoCredicoop",
      generarGecrosEstandar: "devengamientos/generarGecrosEstandar",
      getNombreArchivoDebitoSupervielle:
        "devengamientos/getNombreArchivoDebitoSupervielle",
      getNombreArchivoPagosLinkControl:
        "devengamientos/getNombreArchivoPagosLinkControl",
      getPagosLinkControl: "devengamientos/getPagosLinkControl",
      getPagosLink: "devengamientos/getPagosLink",
      getEnviosByLoteDeuda: "devengamientos/getEnviosByLoteDeuda",
      setAlert: "user/setAlert",
    }),
    openEditDialog(originalValue) {
      this.previousValue = originalValue;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    openModalEnvioSiro(item) {
      this.itemEnvioSiro = item;
      this.modalSeeEnvioSiro = true;
    },
    async getView() {
      const response = await this.getComprobantesEnvio(this.loteDeudaId);
      this.comprobantesLoteDeuda = response;
      this.comprobantesLoteDeuda.forEach((x) => {
        this.entFinId.push(x.entidadFinancieraId);
        this.fPagoId.push(x.formaDePagoId);
      });
      this.getEnviosRealizados();
      this.getEnvioConfigTable();
    },
    async getEnviosRealizados() {
      const response = await this.getEnviosByLoteDeuda(this.loteDeudaId);
      this.enviosRealizados = response;
    },
    async getEnvioConfigTable() {
      const data = {
        entFacId: this.entFacId,
        entidadesFinancierasIds: this.entFinId,
        formasDePagoIds: this.fPagoId,
      };
      const res = await this.getEnvioConfiguracion(data);
      this.envioConfig = res;
      this.comprobantesLoteDeuda.forEach((x) => {
        this.envioConfig.forEach((y) => {
          if (
            (x.entidadFinancieraId === y.entidadFinancieraId &&
              y.formaDePagoId === null) ||
            (y.formaDePagoId === x.formaDePagoId &&
              y.entidadFinancieraId === null) ||
            (x.entidadFinancieraId === y.entidadFinancieraId &&
              y.formaDePagoId === x.formaDePagoId)
          ) {
            y.importe = y.importe + x.importe;
            y.cantidad = y.cantidad + x.cantidad;
          }
        });
      });
      const result = this.envioConfig.filter((x) => x.cantidad > 0);
      this.envioConfig = result;
    },
    closeModalVerEnvio() {
      this.modalSeeEnvioSiro = false;
      this.getEnviosRealizados();
    },
    closeModal() {
      this.modalSeeEnvioSiro = false;
      this.credicoop = false;
      this.macroLink = false;
      this.modalVariableXPantalla = false;
      this.modalExportCsv = false;
      this.envioConfigToSend = {};
      this.csvJsonCompleto = [];
      this.csvJsonToExport = [];
      this.comprobantesIdsToSend = [];
      (this.variablesEnvios = [{ id: 9999, value: null }]),
        (this.validateVariablesOk = null);
      this.getEnvioConfigTable();
    },
    sendLoteDeuda(config) {
      this.comprobantesIdsToSend = [];
      this.modalVariableXPantalla = true;
      this.comprobantesLoteDeuda.forEach((x) => {
        if (
          x.formaDePagoId === config.formaDePagoId &&
          (x.entidadFinancieraId === config.entidadFinancieraId ||
            x.entidadFinancieraId === 0)
        ) {
          this.comprobantesIdsToSend.push(x.compIds);
        }
      });
      this.envioConfigToSend = {
        id: config.enviosConfiguracionId,
        variables: null,
        procesoEnvio: config.procesoEnvioId,
        compIds: [],
      };
      this.envioConfig.forEach((x) => {
        if (x.enviosConfiguracionId === config.enviosConfiguracionId) {
          this.datosPantalla = x.variables.sort(function (a, b) {
            return a.id - b.id;
          });
        }
      });
    },
    saveEditValor(item) {
      if (this.$refs["edit-valor"].validate()) {
        item.campoValor;
        this.variablesEnvios.forEach((x) => {
          if (x.id != item.id) {
            this.variablesEnvios = this.variablesEnvios.filter(
              (y) => y.id !== item.id && y.id !== 9999
            );
            this.variablesEnvios.push({ id: item.id, value: item.campoValor });
            this.envioConfigToSend.variables = this.variablesEnvios;
          } else {
            this.variablesEnvios = this.variablesEnvios.filter(
              (y) => y.id !== item.id && y.id !== 9999
            );
            this.variablesEnvios.push({ id: item.id, value: item.campoValor });
            this.envioConfigToSend.variables = this.variablesEnvios;
          }
        });
      } else {
        item.campoValor = this.previousValue;
        if (x.id != item.id) {
          this.variablesEnvios = this.variablesEnvios.filter(
            (y) => y.id !== item.id && y.id !== 9999
          );
          this.variablesEnvios.push({ id: item.id, value: this.previousValue });
        } else {
          this.variablesEnvios = this.variablesEnvios.filter(
            (y) => y.id !== item.id && y.id !== 9999
          );
          this.variablesEnvios.push({ id: item.id, value: this.previousValue });
        }
      }
      this.setAlert({ type: "success", message: "Actualizado con éxito" });
    },
    cancelEditValor(item) {
      item.campoValor = this.previousValue;
    },
    async enviarLoteWithVariables() {
      this.loadingBtn = true;
      this.setAlert({
        type: "success",
        message: "Descargando archivos, espere un momento por favor.",
      });
      this.variablesEnvios.forEach((x) => {
        if (x.id === 9999) {
          this.validateVariablesOk = true;
          return;
        }
        if (x.value === null || x.value === undefined) {
          this.setAlert({
            type: "warning",
            message:
              "No se puede enviar el lote sin ingresar todos los datos pedidos por pantalla.",
          });
          this.validateVariablesOk = false;
        } else {
          this.validateVariablesOk = true;
        }
      });
      if (this.validateVariablesOk === true) {
        const data = {
          loteDeudaId: this.loteDeudaId,
          enviosConfiguracionId: this.envioConfigToSend.id,
          variables: this.envioConfigToSend.variables,
          compIds: this.comprobantesIdsToSend[0],
        };
        if (this.envioConfigToSend.procesoEnvio === 1) {
          const response = await this.sendSiroApi(data);
          if (response.status === 200) {
            this.setAlert({ type: "success", message: "Enviado con éxito." });
            this.closeModal();
            this.getEnviosRealizados();
          }
        }
        if (this.envioConfigToSend.procesoEnvio === 2) {
          try {
            this.credicoop = true;
            const response = await this.generarArchivoCredicoopCSV(data);
            this.modalExportCsv = true;
            this.csvJsonCompleto = response;
            this.csvJsonCompleto[0].resultObject.forEach((x) => {
              this.csvJsonToExport.push({ Resultado: x });
            });
            this.nombreArchivo = this.csvJsonCompleto[0].nombreArchivo + ".csv";
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 3) {
          try {
            const response = await this.generarVISAMOV(data);
            helper.downloadFile(response, "VISAMOV", "txt");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 4) {
          try {
            const response = await this.generarVISADebito(data);
            helper.downloadFile(response, "DEBLIQD ", "txt");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 5) {
          try {
            const response = await this.generarMastercardDebito(data);
            helper.downloadFile(
              response.data,
              "MASTERCARD/CORDOBESA Crédito",
              "txt"
            );
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 6) {
          try {
            const response = await this.generarNaranjaCredito(data);
            helper.downloadFile(
              response.data,
              `daf-${response.data.substring(1, 10)}`,
              "txt"
            );
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 7) {
          try {
            const response = await this.generarPagoMisCuentasTxt(data);
            helper.downloadFile(response.data, "PagoMisCuentas", "txt");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 8) {
          try {
            const response = await this.generarPagosLink(data);
            this.getEnviosRealizados();
            this.ultimoEnvio =
              this.enviosRealizados[
                this.enviosRealizados.length - 1
              ].enviosLotesId;
            const nombreArchivoLink = await this.getNombreArchivo(
              this.ultimoEnvio
            );
            const nombreArchivoControl =
              await this.getNombreArchivoPagosLinkControl(
                response.data.envioLoteId
              );
            const control = await this.getPagosLinkControl(
              response.data.envioLoteId
            );
            const link = await this.getPagosLink(response.data.envioLoteId);
            this.ultimoEnvio = null;
            helper.downloadFile(control, nombreArchivoControl, "dat");
            helper.downloadFile(link, nombreArchivoLink, "dat");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 9) {
          try {
            const response = await this.generarVISACredito(data);
            helper.downloadFile(response, "DEBLIQC ", "txt");
          } catch (error) {}
        }
        // if (this.envioConfigToSend.procesoEnvio === 10) {
        //   try {
        //     const response = await this.generarNaranjaCredito(data);
        //     helper.downloadFile(response.data, "Banco Provincia Pagos", "txt");
        //     this.modalVariableXPantalla = false;
        //   } catch (error) {}
        // }
        if (this.envioConfigToSend.procesoEnvio === 12) {
          try {
            const response = await this.generarGalicia(data);
            helper.downloadFile(response, "Galicia", "txt");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 13) {
          try {
            this.macroLink = true;
            const response = await this.generarMacroLink(data);
            this.modalExportCsv = true;
            this.resultsLoteDeuda = response;
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 14) {
          try {
            const nombreArchivo = await this.getNombreArchivoDebitoSupervielle(
              data.enviosConfiguracionId
            );
            const response = await this.generarDebitoSupervielle(data);
            helper.downloadFile(response.data, nombreArchivo, "txt");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 15) {
          try {
            const response = await this.generarGecrosEstandar(data);
            // se exporta excel con el detalle, ver los procedimientos y devolver bien el detalle
            let result = [];
            response.forEach((x) =>
              result.push({
                ["N° de agente de cuenta"]: x.detalle1,
                ["DNI"]: x.detalle2,
                ["Nombre"]: x.detalle3,
                ["Período"]: x.detalle4,
                ["Código concepto"]: x.detalle5,
                ["Importe"]: x.detalle6,
                ["Id del comprobante"]: x.detalle7,
              })
            );
            let formato = {
              filas: result,
              hoja: "Resultado",
            };
            helpersExcel.excelExport(formato, "Gecros");
          } catch (error) {}
        }
        if (this.envioConfigToSend.procesoEnvio === 16) {
          try {
            const response = await this.generarPagosDebitoCredicoop(data);
            this.getEnviosRealizados();
            this.ultimoEnvio =
              this.enviosRealizados[
                this.enviosRealizados.length - 1
              ].enviosLotesId;
            const nombreArchivoLink = await this.getNombreArchivo(
              this.ultimoEnvio
            );
            this.ultimoEnvio = null;
            helper.downloadFile(response, nombreArchivoLink, "txt");
          } catch (error) {}
        }
        this.modalVariableXPantalla = false;
      }
      this.loadingBtn = false;
    },
  },
};
</script>

<style scoped>
.totales {
  font-weight: 500;
}
</style>
